import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import AcUserTable from '@molecules/ac-user-table/ac-user-table';
import { useEffect } from 'react';

const AcUserOverviewIQIPTab = ({ store: { users } }) => {
  useEffect(() => {
    users.resetParams();
    users.setOnlyIqip();
    users.list();
  }, []);

  return <AcUserTable type={'iqip'} />;
};

export default withStore(observer(AcUserOverviewIQIPTab));
