import AcUserDetailOverviewTab from './overview/overview.web';
import AcUserOverviewClientsTab from './overview-clients/overview-clients';
import AcUserOverviewMatrixTab from './overview-matrix/overview-matrix';
import AcUserOverviewIqipTab from './overview-iqip/overview-iqip';

export default {
  Overview: AcUserDetailOverviewTab,
  OverviewClients: AcUserOverviewClientsTab,
  OverviewIqip: AcUserOverviewIqipTab,
  Matrix: AcUserOverviewMatrixTab,
};
