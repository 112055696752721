// Imports => Constants
import { ROLES } from '@constants';

// Imports => Utilities
import { AcIsArray } from '@utils';

const getRole = (role) => {
  switch (role) {
    case ROLES.ADMIN:
      return 'Admin';

    case ROLES.SALES_MANAGER:
      return 'Sales Manager';

    case ROLES.IQIP_PROJECT_MANAGER:
      return 'Project Manager';

    case ROLES.PROJECT_ENGINEER:
      return 'Project Engineer';

    case ROLES.GEOTECHNICAL_ADVISOR:
      return 'Geotechnical Advisor';

    case ROLES.IQIP_FLEET_MANAGER:
      return 'Fleet Manager';

    case ROLES.CUSTOMER_SERVICE:
      return 'Customer Service';

    case ROLES.SERVICE_ENGINEER:
      return 'Service Engineer';

    case ROLES.COMPANY_ADMIN:
      return 'Company Admin';

    case ROLES.COMPANY_FLEET_MANAGER:
      return 'Fleet Manager';

    case ROLES.COMPANY_PROJECT_MANAGER:
      return 'Project Manager';

    case ROLES.OPERATOR_A:
      return 'Operator A';

    case ROLES.OPERATOR_B:
      return 'Operator B';

    case ROLES.OPERATOR_C:
      return 'Operator C';

    default:
      return null;
  }
};

export const AcFormatRole = (role) => {
  let result = role;
  const pattern = new RegExp(/\,/gi);

  if (AcIsArray(role) || pattern.test(role)) {
    const arr = AcIsArray(role) ? role : role.split(',');
    const len = arr.length;
    let n = 0;
    result = [];

    for (n; n < len; n++) {
      const line = arr[n].replace(/ /g, '');
      const formatted = getRole(line);
      if (formatted) result.push(formatted);
    }

    result = result.join('<br/>');
  } else {
    result = getRole(role);
  }

  return result;
};
