import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import AcUserTable from '@molecules/ac-user-table/ac-user-table';
import { useEffect } from 'react';

const AcUserOverviewClientsTab = ({ store: { users } }) => {
  useEffect(() => {
    users.resetParams();
    users.setOnlyCustomers();
    users.list();
  }, []);

  return <AcUserTable type={'clients'} />;
};

export default withStore(observer(AcUserOverviewClientsTab));
