// Imports => Utilities
import { AcIsSet, AcIsObject, AcIsEmptyString } from './ac-get-type-of';

export const AcFormatRequestParameters = (
  { query, order_by, per_page, page, onlyCustomers, onlyIqip, roles_query },
  options
) => {
  let params = {};

  if (AcIsSet(query) && !AcIsEmptyString(query)) {
    params.q = query;
  }

  if (AcIsSet(order_by) && AcIsSet(order_by.field)) {
    params.orderBy = order_by.field;
    params.orderByDirection = order_by.direction;

    params.order_by = order_by.field;
    params.order_by_direction = order_by.direction;
  }

  if (AcIsSet(per_page) && per_page !== 0) {
    params.per_page = per_page;
  }

  if (AcIsSet(page) && page !== 0) {
    params.page = page;
  }

  if (AcIsSet(onlyCustomers) && onlyCustomers) {
    params.onlyCustomers = onlyCustomers;
  }

  if (AcIsSet(onlyIqip) && onlyIqip) {
    params.onlyIqip = onlyIqip;
  }

  if (AcIsSet(roles_query) && roles_query.length > 0) {
    params.roles = roles_query;
  }

  if (AcIsSet(options) && AcIsObject(options)) {
    for (let opt in options) {
      params[opt] = options[opt];
    }
  }

  return params;
};
