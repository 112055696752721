// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, PERMISSIONS, ROLES, THEMES, TITLES, TYPES } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsUndefined,
  AcSortBy,
  AcFormatInternalURI,
  AcFormatDate,
  AcFormatRole,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcEditUserModal from '@molecules/ac-edit-user-modal/ac-edit-user-modal.web';
import AcEditUserCredentialsModal from '@molecules/ac-edit-user-credentials-modal/ac-edit-user-credentials-modal.web';

// Imports => Components
import AcDetailsCard from '@components/ac-details-card/ac-details-card.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcToggleInput from '@atoms/ac-toggle-input/ac-toggle-input.web';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-user-detail-overview-tab',
};

const AcUserDetailOverviewTab = ({ data, store: { users, ui } }) => {
  const { can, cannot, is, canAssignRoles } = usePermissions();

  let raw_fields = {
    roles: (data && data.roles.slice()) || [],
  };

  const [fields, setFields] = useState(raw_fields);

  const { handleInputChange } = useFormActions({
    fields,
    setFields,
  });

  useEffect(() => {
    if (canAssignRoles()) {
      let params = {};
      if (data?.is_iqip_user) {
        params.iqipRoles = 1;
      } else {
        params.companyRoles = 1;
      }
      users.get_roles(params);
    }
  }, [data?.is_iqip_user]);

  const handleUpdateUser = () => {
    if (users.is_busy) return;

    if (AcIsSet(data) && AcIsSet(data.roles)) {
      if (AcIsSet(fields) && AcIsSet(fields.roles)) {
        users.update(data, fields).catch((error) => {
          users.get_by_id(data.id);
        });
      }
    }
  };

  const handleUpdateUserRoles = () => {
    if (users.is_busy) return;

    if (AcIsSet(data) && AcIsSet(data.roles)) {
      if (AcIsSet(fields) && AcIsSet(fields.roles)) {
        users
          .patch({ id: data.id, name: data.name, roles: fields.roles })
          .catch((error) => {
            users.get_by_id(data.id);
          });
      }
    }
  };

  const displayEditCredentialsModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: TITLES.EDIT_USER_CREDENTIALS,
      body: <AcEditUserCredentialsModal data={data} submit={users.update} />,
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const displayEditModal = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    await ui.reset(KEYS.MODAL);
    await ui.set(KEYS.MODAL, {
      title: `${TITLES.EDIT_USER}: ${data.name}`,
      progress: {
        total_steps:
          can(PERMISSIONS.USER.MANAGE_ALL) && !data.is_iqip_user ? 3 : 2,
        current_step: 0,
      },
      body: (
        <AcEditUserModal
          data={data}
          multistep={{
            roles: true,
            companies: can(PERMISSIONS.USER.MANAGE_ALL) && !data.is_iqip_user,
          }}
          submit={users.update}
        />
      ),
      closeable: true,
      visible: true,
      actions: [],
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    });
  };

  const renderDetails = useMemo(() => {
    if (!data) return null;

    const { name, email, phone, company, companies, is_iqip_user } = data;

    const result = [
      {
        label: 'Name',
        value: name || '-',
      },
      {
        label: 'Email address',
        value: email || '-',
      },
      {
        label: 'Phone number',
        value: phone || '-',
      },
    ];

    if (is_iqip_user) {
      result.push({
        label: 'IQIP Employee',
        value: 'Yes',
      });
    } else if (companies?.length) {
      result.push({
        label: 'Companies',
        value: companies
          ?.map((obj) => {
            return obj.name;
          })
          .join(', '),
      });
    } else if (AcIsSet(company)) {
      const company_route =
        (can(PERMISSIONS.COMPANY.READ) &&
          company &&
          AcFormatInternalURI(
            { id: company.id, entity: KEYS.COMPANY },
            'View company'
          )) ||
        null;

      result.push({
        label: 'Company',
        value: (company && company.name) || '-',
        type: TYPES.LINK,
        to: company_route,
      });
    }

    const edit = can(PERMISSIONS.USER.MANAGE) && displayEditModal;

    return <AcDetailsCard title={TITLES.DETAILS} items={result} edit={edit} />;
  }, [data]);

  const renderRoles = useMemo(() => {
    if (!AcIsSet(data)) return null;
    if (!users.current_roles) return null;

    const collection = Object.values(ROLES);

    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const role = collection[n];

      const available = AcIsSet(
        users.current_roles.find((item) => item.name === role)
      );
      if (available) {
        const callback = async (event, name, value, type, selected) => {
          await handleInputChange(event, name, value, type, selected);
          handleUpdateUserRoles();
        };

        const options = {
          type: TYPES.CHECKBOX,
          name: 'roles',
          id: `ac-roles-toggle-${role}`,
          value: role,
          checked: fields.roles && fields.roles.indexOf(role) > -1,
          disabled:
            users.is_busy ||
            cannot(PERMISSIONS.USER.MANAGE) ||
            role === 'admin',
          callback: can(PERMISSIONS.USER.MANAGE) && callback,
        };

        let label = AcFormatRole(role);

        const object = (
          <AcRow key={`ac-roles-toggle-row-${role}`}>
            <AcColumn xs={12}>
              <AcToggleInput {...options}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: label,
                  }}
                />
              </AcToggleInput>
            </AcColumn>
          </AcRow>
        );

        result.push(object);
      }
    }

    return result;
  }, [data, fields, fields.roles, users.is_busy, users.current_roles]);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  });

  return (
    <div className={getMainClassNames}>
      <AcContainer fluid>
        <AcRow>
          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User details
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>{renderDetails}</AcColumn>
            </AcRow>
          </AcColumn>

          <AcColumn xs={12} sm={6}>
            <AcRow>
              <AcColumn>
                <AcHeading tag={'h2'} rank={5} className={'h-margin-bottom-25'}>
                  User roles
                </AcHeading>
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcCard>{renderRoles}</AcCard>
              </AcColumn>
            </AcRow>
          </AcColumn>
        </AcRow>
      </AcContainer>
    </div>
  );
};

export default withStore(observer(AcUserDetailOverviewTab));
